import EmpleosDynamicDetail from '../../components/empleos/EmpleosDynamicDetail'
import React from "react";
import {Router} from "@reach/router"
const EmpleosDynamicPage = () => {
    return (
        <Router basepath='/trabaja-con-nosotros'>
            <EmpleosDynamicDetail path='/:slug' />
        </Router>
    )
}
export default EmpleosDynamicPage